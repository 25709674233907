import React, { useEffect, useState } from "react";
import StyledCard from "../components/Common/StyledCard";
import { FaAngleDown, FaPlayCircle } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { ToastError } from "../components/Common/ToastMessage";
import { userApiAction } from "../redux/user/action";
import VideoScreen from "../components/VideoScreen";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";
import Loader from "../components/Common/Loader";

export default function FavVideo() {
  const [videoData, setVideoData] = useState([]);
  const [videoScreen, setVideoScreen] = useState(false);
  const [activeVideoUrl, setActiveVideoUrl] = useState("");
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const { user, string } = useSelector((state) => state.persistReducer);

  function transformData(data) {
    data.map((items) => (items.isActive = false));
    return data;
  }

  const getVideo = () => {
    const data = {
      token: user.token,
      userid: user.user_id,
      method: "get_bookmark_videos",
    };
    setLoader(true);
    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError("Something Went Wrong");
        } else {
          setVideoData(transformData(e.data.bookmark_videos));
          setLoader(false);
        }
        setLoader(false);
      })
    );
  };

  const addToBookmark = (chapterid, videoid, scormid, courseid, ix, ix2) => {
    setVideoData((prevData) => {
      return prevData.map((course, courseIndex) => {
        if (courseIndex !== ix) return course;
        return {
          ...course,
          chapters: course.chapters.map((chapter, chapterIndex) => {
            if (chapterIndex !== ix2) return chapter;
            return {
              ...chapter,
              videos: chapter.videos.filter(
                (video) => video.videoid !== videoid
              ),
            };
          }),
        };
      });
    });

    const data = {
      token: user.token,
      userid: user.user_id,
      method: "save_video_as_bookmark",
      courseid: courseid,
      videoid,
      chapterid,
      scormid,
    };
    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError(string.msg.error);
          return;
        }
      })
    );
  };

  const updateIsActive = (ix) => {
    setVideoData((prevData) =>
      prevData.map((item, idx) =>
        idx === ix ? { ...item, isActive: !item.isActive } : item
      )
    );
  };

  useEffect(() => {
    setLoader(true);
    getVideo();
  }, []);

  return (
    <div>
      {loader && <Loader />}
      <h2 style={{ paddingLeft: "10px" }}>{string.favouriteVideo}</h2>
      {videoData &&
        videoData.map((item, ix) => (
          <div key={item.id}>
            <StyledCard className={item.isActive ? "active" : ""}>
              <button
                className="title"
                onClick={() => {
                  updateIsActive(ix);
                }}
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div className="title_name">
                  <span className="arrow_icon">
                    <FaAngleDown />
                  </span>
                  <h3>{item.course_name}</h3>
                </div>
              </button>
              <div className="content">
                {item.chapters.map((chapt, ix2) => (
                  <React.Fragment key={chapt.chapterid}>
                    <h3 className="chapter_title">{chapt.chapter_name}</h3>
                    {chapt.videos.map((video, ix3) => (
                      <ul key={video.videoid}>
                        <li
                          className="play_icon"
                          onClick={() => {
                            setActiveVideoUrl(video.scorm_url);
                            setVideoScreen(true);
                          }}
                        >
                          <FaPlayCircle />
                        </li>
                        <li
                          onClick={() => {
                            setActiveVideoUrl(video.scorm_url);
                            setVideoScreen(true);
                          }}
                        >
                          {video.video_name}
                        </li>
                        <li>{video.duration} </li>
                        <li
                          className="flag_icon"
                          onClick={() => {
                            addToBookmark(
                              video?.chapterid,
                              video?.videoid,
                              video?.scormid,
                              video?.courseid,
                              ix,
                              ix2
                            );
                          }}
                        >
                          {!video.is_bookmark ? (
                            <IoBookmark />
                          ) : (
                            <IoBookmarkOutline />
                          )}
                        </li>
                      </ul>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            </StyledCard>
          </div>
        ))}

      {/* ==========  Video Screen  ==============  */}
      <VideoScreen
        isOpen={videoScreen}
        handleClose={(e) => setVideoScreen(!videoScreen)}
        url={activeVideoUrl}
      />
    </div>
  );
}
