import React from "react";
import styled from "styled-components";
import { MdGroups } from "react-icons/md";
import { FaStar } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function CoursesCard({ data }) {
  const { user, string } = useSelector((state) => state.persistReducer);

  const words = data.summary.split(" ");
  const navigate = useNavigate();
  const limitedSummary = words.slice(0, 25).join(" ") + "...";
  const rating = Number(data.rating) || 0;

  return (
    <Root
      progress={(data.view_activity / data.total_activity) * 100}
      onClick={() => {
        navigate(`/courses/${data.id}/${data.is_ai_course}`);
      }}
    >
      <div className="parent_box">
        <div className="content_box">
          <span></span>
          <div>
            <h3>{data?.fullname}</h3>
            <div dangerouslySetInnerHTML={{ __html: limitedSummary }}></div>
            <div className="duration_box">
              <h4>
                <b>{string.duration} : {data?.duration}</b>
              </h4>
              <h4>
                {string.learnBytes}: {data.view_activity.toString().padStart(2, "0")}/
                {data.total_activity.toString().padStart(2, "0")}
              </h4>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                <MdGroups
                  style={{ height: "40px", width: "40px", marginRight: "10px" }}
                />
              </span>
              <span style={{ fontWeight: "600" }}>3K Users</span>
            </div>
          </div>
        </div>
        <div className="completed-percentage progress">
          <div>{((data.view_activity / data.total_activity) * 100).toFixed(1)}%</div>
        </div>
      </div>
      <div className="bottom_bar">
        <ul className="rating_box">
          <li style={{ marginTop: "-2px", marginRight: "2px" }}>
            <b>{rating}</b>
          </li>
          {[0, 1, 2, 3, 4].map((idx) => (
            <li key={idx}>
              <FaStar
                style={{
                  backgroundColor: "#3ca3a9",
                  color: idx < rating ? "#ffd029" : "#ccc",
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    </Root>
  );
}

const Root = styled.div`
  border-radius: 10px;
  background-color: #eaf2f1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  .parent_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .content_box {
      height: calc(100% - 16px);
      display: flex;
      align-items: center;
      gap: 10px;

      > span {
        height: 100%;
        position: relative;
        min-width: 8px;
        max-width: 8px;
        background: #3ca3a9;
        border-radius: 20px;
      }

      > h4 > b {
        font-weight: 600;
      }

      .duration_box {
        display: flex;
        gap: 5px;
      }
    }
  }

  .bottom_bar {
    background: #3ca3a9;
    height: 16px;
    position: relative;

    .rating_box {
      display: flex;
      align-items: center;
      position: absolute;
      right: 26px;
      top: -30px;
      background: #3ca3a9;
      padding: 6px 10px;
      border-radius: 10px 10px 0px 0px;
      color: #fff;
    }
  }

  .completed-percentage {
    width: 100px;
    height: 100px;
    padding: 10px;
    border-radius: 100%;
  }

  .completed-percentage > div {
    height: 80px;
    width: 80px;
    background-color: white;
    box-shadow: 0 0 2px 2px #fbfbfb;
    padding: 20px 15px 10px;
    font-weight: 600;
    font-size: 1.35rem;
    border-radius: 100%;
  }

  .progress {
    background: conic-gradient(
      #3ca3a9 0% ${(props) => props.progress || 50}%,
      #ddd ${(props) => props.progress || 50}% 100%
    );
  }
`;
