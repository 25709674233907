import React from "react";
import { ImCross } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function VideoScreen({ isOpen, handleClose, url }) {
  if (!isOpen) return null;
  return (
    <VideoScreenWrapper>
      <VideoOverlay />
      <VideoContent>
        <Iframe
          src={url}
          width="80%"
          height="auto"
          allow="autoplay"
          // allowFullScreen
          title="Video"
        />
        <CloseButton
          onClick={() => {
            handleClose(true);
          }}
        >
          <ImCross />
        </CloseButton>
      </VideoContent>
    </VideoScreenWrapper>
  );
}

const VideoScreenWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoContent = styled.div`
  position: relative;
  border-radius: 8px;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000061;
  backdrop-filter: blur(4px);
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: black; /* Visible background color */
  border: none;
  font-size: 20px;
  height: 25px;
  width: 25px;
  cursor: pointer;
  z-index: 2; /* Ensure it appears above the video */
  color: #ffff;
`;

const Iframe = styled.iframe`
  border: none;
  height: 100%;
  width: 100%;
`;
