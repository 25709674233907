
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import StyledCard from "./StyledCard";
import { FaAngleDown, FaPlayCircle } from "react-icons/fa";
import VideoScreen from "../VideoScreen";
import { useDispatch, useSelector } from "react-redux";
import { userApiAction } from "../../redux/user/action";
import { ToastError } from "./ToastMessage";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";

export default function CoursesAccordion({ data, count, isAICourse }) {
  const [isOpen, setIsOpen] = useState(false);
  const [courses, setCourses] = useState({});
  const [videoScreen, setVideoScreen] = useState(false);
  const [activeVideoUrl, setActiveVideoUrl] = useState("");
  const { user, string } = useSelector((state) => state.persistReducer);
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState({
    status: "Pending",
    questions: "03/20",
    points: "40*",
  });
  const addToBookmark = (videoid, chapterid, scormid) => {
    setCourses({
      ...courses,
      videos: courses.videos.map((item) =>
        item.id === videoid ? { ...item, is_bookmark: !item.is_bookmark } : item
      ),
    });

    const data = {
      token: user.token,
      userid: user.user_id,
      method: "save_video_as_bookmark",
      courseid: courses.courseid,
      videoid,
      chapterid,
      scormid,
    };
    setLoader(true);
    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError(string.msg.error);
        } else {
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    if (data) {
      setCourses(data);
    }
  }, [data]);
  console.log("454545", data);
  return (
    <Root>
      <StyledCard className={isOpen ? "active" : ""}>
        <button
          className="title"
          onClick={() => {
            data.is_quiz === "1"
              ? navigate(`/quiz/${courses.courseid}/${isAICourse}`)
              : setIsOpen(!isOpen);
          }}
        >
          <div className="title_section">
            <GoDotFill />
            <h3 style={{ textAlign: "start", color: isOpen ? "#fff" : "gray" }}>
              {string.section} {count}
            </h3>
          </div>
          <div className="title_name">
            <span className="arrow_icon">
              {data.is_quiz !== "1" && <FaAngleDown />}
            </span>
            <h3>{courses.name}</h3>
          </div>
        </button>
        <div className="content">
          {courses?.videos?.map((video) => (
            <ul key={video.videoid}>
              <li
                className={`play_icon ${video.user_progress}`}
                onClick={() => {
                  setActiveVideoUrl(video.scorm_url);
                  setVideoScreen(true);
                }}
              >
                <FaPlayCircle />
              </li>
              <li
                onClick={() => {
                  setActiveVideoUrl(video.scorm_url);
                  setVideoScreen(true);
                }}
              >
                {video.name}
              </li>
              <li>{video.duration}</li>
              <li
                className="flag_icon"
                onClick={() => {
                  !loader &&
                    addToBookmark(video.id, video.chapterid, video.scormid);
                }}
              >
                {video.is_bookmark ? <IoBookmark /> : <IoBookmarkOutline />}
              </li>
            </ul>
          ))}
        </div>
      </StyledCard>
      {/* ==========  Video Screen  ==============  */}
      <VideoScreen
        isOpen={videoScreen}
        handleClose={(e) => setVideoScreen(!videoScreen)}
        url={activeVideoUrl}
      />
    </Root>
  );
}

const Root = styled.section``;
const QuizSection = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;

  @supports (grid-template-columns: minmax(0, 1fr)) {
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto auto;
  }
`;

const QuizDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 10px;
  font-size: 0.9rem;
`;

