import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { userApiAction } from "../redux/user/action";
import { ToastError } from "../components/Common/ToastMessage";
import Loader from "../components/Common/Loader";
import { useNavigate } from "react-router-dom";

export default function AICourses(data) {
  const [loader, setLoader] = useState(true);
  const [courseData, setCourseData] = useState([]);
  const dispatch = useDispatch();
  const { user, string } = useSelector((state) => state.persistReducer);
  const navigate = useNavigate();
  const getCourses = () => {
    const data = {
      token: user.token,
      userid: user.user_id,
      method: "get_ai_courses",
    };
    setLoader(true);
    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError(string.msg.error);
        } else {
          setCourseData(e.data.courses);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    getCourses();
  }, []);


  return (
    <Root>
      {loader && <Loader aiLoader={true}/>}
      {courseData &&
        courseData.map((items,ix) => {
          const words = items?.summary?.split(" ");
          const limitedSummary = words?.slice(0, 50).join(" ") + "...";
          return (
            <div
              key={ix}
              className="content_box"
              onClick={() => {
                navigate(`/courses/${items.id}/${items.is_ai_course}`);
              }}
            >
              <span></span>
              <div>
                <h3>{items?.fullname}</h3>
                <div dangerouslySetInnerHTML={{ __html: limitedSummary }}></div>
              </div>
            </div>
          );
        })}
    </Root>
  );
}
const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  .content_box {
    display: flex;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    gap: 10px;
    background-color: #eaf2f1;
    border-radius: 10px;
    > span {
      min-width: 8px;
      max-width: 8px;
      background: #3ca3a9;
      border-radius: 20px;
    }
    > h4 > b {
      font-weight: 600;
    }
    .duration_box {
      display: flex;
    }
  }
`;
