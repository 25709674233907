import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import Login from "./Pages/Login";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Home from "./Pages/Home";
import Courses from "./Pages/Courses";
import AICourses from "./Pages/AICourses";
import Layout from "./components/Layout";
import CoursesDetail from "./Pages/CoursesDetail";
import FavVideo from "./Pages/FavVideo";
import Certification from "./Pages/Certification";
import { Toaster } from "react-hot-toast";
import Test from "./Pages/Test";
import Quiz from "./Pages/Quiz";
import QuizResult from "./Pages/QuizResult";
import Chat from "./Pages/Chat";
import { useEffect, useState } from "react";
import { stringAction } from "./redux/user/action";
import { stringEng } from "./utils/services/string";
import Banner from "./components/Home/Banner";

function App() {
  const dispatch = useDispatch();
  const [showApp, setShowApp] = useState(false);
  const { user, string } = useSelector((state) => state.persistReducer);
  useEffect(() => {
    dispatch(stringAction(stringEng));
  }, [dispatch]);

  useEffect(() => {
    if (string?.hello) {
      setShowApp(true);
    }
  }, [string]);

  return (
    showApp && (
      <Root className="App">
        {user?.token ? (
          <Layout>
            <Routes>
              {/* <Route path="/" element={<Home />} />
            <Route path="*" element={<Home />} /> */}
              <Route path="/" element={<Banner />} />
              <Route path="*" element={<Banner />} />
              <Route path="/my-courses" element={<Courses />} />
              <Route path="/ai-courses" element={<AICourses />} />
              <Route path="/fav-video" element={<FavVideo />} />
              <Route
                path="/courses/:id/:isAICourse"
                element={<CoursesDetail />}
              />
              <Route path="/certification" element={<Certification />} />
              <Route path="/quiz/:id/:practice" element={<Quiz />} />
              <Route
                path="/quiz-result/:courseid/:quizid/:is_practice"
                element={<QuizResult />}
              />
              <Route path="/test" element={<Test />} />
              <Route path="/chat" element={<Chat />} />
            </Routes>
          </Layout>
        ) : (
          <>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </>
        )}
        <Toaster position="top-center" />
      </Root>
    )
  );
}

export default App;
const Root = styled.section`
  background-image: url("/assets/images/body_background.png");
  height: 100vh;
  .primary_button {
    background-color: #319ea4;
    color: #fff;
    padding: 10px;
    cursor: pointer;
    border-radius: 50px;
    border: none;
  }
`;
