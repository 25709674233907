import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AIQuizEndPop({ isOpen, pass, id }) {
  const navigate = useNavigate();
  const [showGif, setShowGif] = useState(true);
  const { user, string } = useSelector((state) => state.persistReducer);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setShowGif(false);
      }, 2000); // GIF lasts for 2 seconds
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handlePass = () => {
    navigate(`/courses/${id}/0`);
  };
  const handleFail = () => {
    navigate(`/courses/${id}/1`);
  };

  if (!isOpen || (pass !== "yes" && pass !== "no")) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <Root>
          {pass === "yes" && (
            <div className={`dialog-overlay ${showGif ? "show-gif" : ""}`}>
              <div
                className={`dialog-box ${showGif ? "show-gif" : ""}`}
                onClick={(e) => e.stopPropagation()}
              >
                <button className="back_btn" onClick={handlePass}>
                  <ImCross />
                </button>
                <div className="check_style">
                  <FaCheck />
                </div>
                <div>
                  <h3>{string.msg.congrat}</h3>
                  <p>{string.msg.passQuiz1}</p>
                  <p>{string.msg.passQuiz2}</p>
                </div>
                <button
                  className="primary_button"
                  style={{ width: "100%" }}
                  onClick={handlePass}
                >
                  {string.btn.continue}
                </button>
              </div>
            </div>
          )}
          {pass === "no" && (
            <div className="dialog-overlay">
              <div className="dialog-box" onClick={(e) => e.stopPropagation()}>
                <button className="back_btn" onClick={handleFail}>
                  <ImCross />
                </button>
                <div className="cross_style">
                  <ImCross />
                </div>
                <div>
                  <h3>{string.aiCourses}</h3>
                  <p>{string.msg.failedQuiz}</p>
                </div>
                <button
                  className="primary_button"
                  style={{ width: "100%" }}
                  onClick={handleFail}
                >
                  {string.btn.continue}
                </button>
              </div>
            </div>
          )}
        </Root>
      )}
    </>
  );
}

const fadeInOut = keyframes`
  0% {opacity:0}
  25% {opacity:0.25}
  50% {opacity:0.5}
  75% {opacity:0.75}
  100% { opacity:1}
`;

const Root = styled.div`
  text-align: center;
  margin-top: 50px;
  h3 {
    text-align: center;
  }

  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &.show-gif {
      background: url("/assets/images/congratulations.gif") no-repeat center
        center;
      background-size: cover;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(1px);
      animation: ${fadeInOut} 3.5s forwards;
    }
  }

  .dialog-box {
    background-color: white;
    position: relative;
    padding: 20px 20px 5px 20px;
    border-radius: 10px;
    width: 100%;
    gap: 15px;
    max-width: 350px;
    height: 100%;
    max-height: 300px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    &.show-gif {
      background: url("/assets/images/congratulations.gif") no-repeat center
        center;
      background-size: cover;
      background-color: #fff;
      animation: ${fadeInOut} 2.5s forwards;
    }

    .back_btn {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 15px;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }

    .cross_style {
      background-color: red;
      height: 50px;
      width: 50px;
      display: flex;
      font-size: 25px;
      color: #ffff;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      padding: 3px;
    }
    .check_style {
      background-color: lightgreen;
      height: 50px;
      width: 50px;
      display: flex;
      font-size: 25px;
      color: #ffff;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      padding: 3px;
    }
  }
`;
