import React from "react";
import styled from "styled-components";

export default function CertificationCard({ data }) {
  const openImageInNewTab = () => {
    const imageUrl = '/assets/images/certificate.jpg';
    window.open(imageUrl, '_blank');
  };
  console.log("dataCertification", data);
  return (
    <Root onClick={openImageInNewTab}>
      <div className="logo_section" >
        <div className="logo">
          <img src="/assets/icons/learnbytesSingle.svg" alt="logo" />
        </div>
        <h3>{data?.certificate_name?.toUpperCase()}</h3>
      </div>
      <div className="main_section">
        <img src="/assets/icons/certificate_semi.svg" alt="logo"/>
        <h3>{data?.coursename}</h3>
        <p>{data?.issue_date}</p>
      </div>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  cursor: pointer;
  /* flex:1; */
  width: 50%;
  border-radius: 12px;
  /* max-width: 650px; */
  background-color: #cfecea;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  .logo_section {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    border-radius: 12px;
    padding: 10px 0;
    background-color: #fff;
    margin: 1px;
    img{
      height: 50px;
      width: 50px;
      margin: 10px;
    }
    h3{
        text-align:center;
        font-size: 15px;
    }
  }
  .main_section {
    flex: 5;
    padding: 20px;
    display:flex;
    justify-content:center;
    flex-direction: column;
    position: relative;
    h3{
        font-weight: 600;
    }
    img{
        height: 25px;
        width: 25px;
        position:absolute;
        top:20px;
        right: 20px;
    }
  }
`;
