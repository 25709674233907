import React, { useState } from "react";
import styled from "styled-components";
import Input from "../components/Common/Input";
import { useDispatch, useSelector } from "react-redux";
import { userApiAction, userDataAction } from "../redux/user/action";
import Loader from "../components/Common/Loader";
import { ToastError, ToastSuccess } from "../components/Common/ToastMessage";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [forgetScreen, setForgetScreen] = useState(false);
  const navigate = useNavigate();
  const { string } = useSelector((items) => items.persistReducer);
  const [loginData, setLoginData] = useState({
    username: "",
    passowrd: "",
    method: "login",
  });

  const handleClick = () => {
    if (loginData.username.length > 2 && loginData.passowrd.length > 2) {
      setLoader(true);
      dispatch(
        userApiAction(loginData, (e) => {
          if (e == "error") {
            ToastError(string.msg.error);
          } else {
            dispatch(userDataAction(e.data.login_response));
            localStorage.setItem("token", e.data.login_response.token);
            navigate("/");
          }
          setLoader(false);
        })
      );
    }
  };

  const handleForgetPass = () => {
    setLoader(true);
    if (loginData.username.length < 2) {
      ToastError(string.msg.invalidUser);
      return;
    }
    const data = {
      username: loginData?.username,
      method: "reset_password",
    };

    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError(string.msg.error);
        } else {
          ToastSuccess(string.msg.verificationLink)
        }
        setLoader(false);
        setForgetScreen(false)
      })
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  console.log("776767", loginData.username);

  return (
    <Root>
      {loader && <Loader />}
      <div className="login_cover">
        <img src="/assets/images/login_img.png" alt="Logo" />
      </div>
      {forgetScreen ? (
        <div className="login_form">
          <h1>{string.forgetPass}</h1>
          <Input
            placeholder={string.msg.username}
            icon={"/assets/icons/uil_lock-alt.svg"}
            value={loginData.username}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setLoginData({ ...loginData, username: e.target.value });
            }}
          />
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              className="frgt_btn2"
              onClick={() => {
                setForgetScreen(false);
              }}
            >
              {string.btn.backToLog}
            </button>
          </div>
          <button
            className="primary_button"
            onClick={() => {
              handleForgetPass();
            }}
          >
            {string.btn.sendLink}
          </button>
        </div>
      ) : (
        <div className="login_form">
          <h1>{string.login}</h1>
          <Input
            placeholder={string.msg.username}
            icon={"/assets/icons/uil_lock-alt.svg"}
            value={loginData.username}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setLoginData({ ...loginData, username: e.target.value });
            }}
          />
          <div className="password_field">
            <Input
              placeholder={string.msg.password}
              type={"password"}
              icon={"/assets/icons/password_icon.svg"}
              value={loginData.passowrd}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                setLoginData({ ...loginData, passowrd: e.target.value });
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              className="frgt_btn2"
              onClick={() => {
                setForgetScreen(true);
              }}
            >
              {string.forgetPass} ?
            </button>
          </div>
          <button
            className="primary_button"
            onClick={() => {
              handleClick();
            }}
          >
            {string.btn.login}
          </button>
        </div>
      )}
    </Root>
  );
}
const Root = styled.section`
  .login_cover {
    height: 274px;
    background-image: url("/assets/images/login_background.svg");
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    > img {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 42px;
    }
  }

  .login_form {
    border: 1.5px solid #dfe1e6;
    border-radius: 10px;
    width: 440px;
    margin: auto;
    padding: 10px;
    h1 {
      margin: 0px 0px 20px;
    }
    .password_field {
      position: relative;
      padding: 10px 0px;
      .frgt_btn {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        border: none;
        background: none;
        color: #232e83;
      }
    }
    .primary_button {
      width: 100%;
      margin-top: 25px;
    }
    .frgt_btn2 {
      border: none;
      background: none;
      color: #232e83;
    }
  }
`;
