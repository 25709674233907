import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GiProgression } from "react-icons/gi";
import { GrResume } from "react-icons/gr";
import { GrStatusGood } from "react-icons/gr";
import { ImCross } from "react-icons/im";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userApiAction } from "../redux/user/action";
import { ToastError } from "../components/Common/ToastMessage";
import Loader from "../components/Common/Loader";
import AIQuizEndPop from "../components/Common/AIQuizEndPop";
import AiLoader from "../components/Common/AiLoader";

export default function QuizResult() {
  const { courseid, quizid, is_practice } = useParams();
  const [quizData, setQuizData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [aiLoader, setAILoader] = useState(false);
  const { user, string } = useSelector((state) => state.persistReducer);
  const [stats, setStats] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getResult = (courseid, quizid, is_practice) => {
    const data = {
      token: user.token,
      userid: user.user_id,
      method: "quiz_attempt_detail",
      courseid,
      quizid,
      is_practice,
    };
    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError(string.msg.error);
        } else {
          setQuizData(e.data.quiz.quiz_stats.quiz_chapters_data);
          setStats(e.data.quiz.quiz_stats.quiz_overall_data);
        }
        setLoader(false);
      })
    );
  };

  const createAICourse = () => {
    setLoader(true);
    setAILoader(true);
    setTimeout(() => {
      setLoader(false);
      setAILoader(false);
      navigate("/ai-courses");
    }, 3000);
  };

  useEffect(() => {
    setLoader(true);
    if (courseid && quizid && is_practice) {
      getResult(courseid, quizid, is_practice);
    }
  }, [courseid, quizid, is_practice]);

  console.log("stat", stats);
  return (
    <>
      {loader ? (
        aiLoader ? (
          <AiLoader />
        ) : (
          <Loader />
        )
      ) : is_practice == "1" ? (
        <AIQuizEndPop
          isOpen={true}
          pass={
            stats?.total_correct_annswer &&
            stats?.total_correct_annswer == stats?.total_question
              ? "yes"
              : "no"
          }
          id={courseid}
          isAI={is_practice}
        />
      ) : (
        <Root progress={33}>
          <div className="primary_div">
            <div className="head_box">
              <h3>{string.quizAttemptDetail}</h3>
            </div>
            <div className="primary_result">
              <div>
                <GiProgression />
                <div>
                  <p>
                    {stats?.total_attempt < 10
                      ? `0${stats?.total_attempt}`
                      : stats?.total_attempt}
                  </p>
                  <p>{string.attempts}</p>
                </div>
              </div>
              <div>
                <GrResume />
                <div>
                  <p>
                    {stats?.total_question < 10
                      ? `0${stats?.total_question}`
                      : stats?.total_question}
                  </p>
                  <p>{string.totalQuestion}</p>
                </div>
              </div>
              <div>
                <GrStatusGood />
                <div>
                  <p>
                    {stats?.total_correct_annswer < 10
                      ? `0${stats?.total_correct_annswer}`
                      : stats?.total_correct_annswer}
                  </p>
                  <p>{string.correct}</p>
                </div>
              </div>
              <div>
                <ImCross />
                <div>
                  <p>
                    {stats?.total_question - stats.total_correct_annswer < 10
                      ? `0${
                          stats?.total_question - stats.total_correct_annswer
                        }`
                      : stats?.total_question - stats.total_correct_annswer}
                  </p>
                  <p>{string.wrong}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="secondary_div">
            <div className="head_box">
              <h3>{string.aiAnalysisHead}</h3>
            </div>
            <div className="secondary_reault">
              {quizData &&
                quizData?.map((items) => {
                  return (
                    <div>
                      <div className="completed-percentage progress">
                        <div className="percentage">
                          {(
                            (items.total_correct_annswer /
                              items?.total_question) *
                            100
                          ).toFixed(1)}
                          %
                        </div>
                      </div>
                      {items?.name}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="ai_course">
            {stats?.total_correct_annswer == stats?.total_question ? (
              <>
                <h3>{string.msg.congrat}</h3>
                <button
                  className="primary_button"
                  style={{ width: "100px" }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  {string.btn.clickHere}
                </button>
              </>
            ) : (
              <>
                <h3>{string.aiCourseCreation}</h3>
                <button
                  className="primary_button"
                  style={{ width: "100px" }}
                  onClick={() => {
                    createAICourse();
                  }}
                >
                  {string.btn.clickHere}
                </button>
              </>
            )}
          </div>
        </Root>
      )}
    </>
  );
}

const Root = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  > div {
    width: 100%;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    overflow: hidden;
  }
  .primary_result {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    > div {
      width: 50%;
      padding: 15px;
      display: flex;
      gap: 10px;
      align-items: center;
      border: 1px solid lightgray;
      svg {
        font-size: 25px;
      }
    }
  }
  .secondary_reault {
    display: flex;
    flex-wrap: wrap;
    > div {
      display: flex;
      width: 50%;
      padding: 15px;
      gap: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid lightgray;
    }
  }
  .ai_course {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .head_box {
    background-color: #008890;
    color: #ffff;
    padding: 15px;
    > h3 {
      text-align: center;
    }
  }

  .completed-percentage {
    width: 80px;
    height: 80px;
    padding: 10px;
    border-radius: 100%;
  }
  .completed-percentage > div {
    height: 60px;
    width: 60px;
    background-color: white;
    box-shadow: 0 0 2px 2px #fbfbfb;
    padding: 20px 15px 10px;
    font-weight: 600;
    font-size: 1.15rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .progress {
    background: conic-gradient(
      #3ca3a9 0% ${(props) => props.progress || 50}%,
      #ddd ${(props) => props.progress || 50}% 100%
    );
  }
`;
