import React from "react";
import styled from "styled-components";

export default function StyledCard({ className, children }) {
  return <Root className={className}>{children}</Root>;
}
const Root = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 18px 0px #00000017;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #fff;
  &.active {
    border: 1px solid #319ea4;
    .arrow_icon {
      color: #fff;
      transform: rotate(180deg);
    }
    .title {
      background-color: #319ea4;
      color: #fff;
    }
    .content {
      display: block;
    }
  }

  .title {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* gap: 10px; */
    cursor: pointer;
    padding: 18px;
    background: none;
    border: none;
    width: 100%;
  }
  .title_section {
    width: 100%;
    display: flex;
    gap: 3px;
    align-items: center;
    h3{
      font-size: 18px;
    }
    svg{
      color:orange;
      font-size: 18px;
    }
  }
  .title_name {
    display: flex;
    gap: 5px;
    width: 100%;
    align-items:center;
    h3{
      text-align:left;
    }
  }
  .arrow_icon {
    display: flex;
    align-items: center;
  }
  .content {
    display: none;
    padding: 0px 18px 18px 18px;
    > ul {
      display: grid;
      grid-template-columns: 20px 1fr 50px 14px;
      gap: 10px;
      cursor: pointer;
      .play_icon {
        margin-top: 2px;
        font-size: 20px;
        &.nopreview {
          color: #fd4242;
        }
        &.inprogress {
          color: #f5893a;
        }
        &.completed {
          color: #31dc56;
        }
        color: #31dc56;
      }
      .flag_icon {
        margin-top: 3px;
        color: #319ea4;
        font-size: 22px;
      }
    }
    .chapter_title {
      margin: 20px 0 10px 30px;
    }
  }
`;
