import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CoursesAccordion from "../components/Common/CoursesAccordion";
import { IoIosStar } from "react-icons/io";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userApiAction } from "../redux/user/action";
import { ToastError } from "../components/Common/ToastMessage";
import Loader from "../components/Common/Loader";
import RatingPop from "../components/Common/RatingPop";

export default function CoursesDetail() {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(true);
  const [ratePop, setRatePop] = useState(false);

  const dispatch = useDispatch();
  const { user, string } = useSelector((state) => state.persistReducer);
  const { id, isAICourse } = useParams();

  const getCoursesDetail = (id) => {
    const data = {
      token: user.token,
      userid: user.user_id,
      method: "get_course_detail",
      courseid: id,
      is_ai_course: isAICourse,
    };
    setLoader(true);
    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError(string.msg.error);
        } else {
          setData(e.data.course_detail);
        }
        setLoader(false);
      })
    );
  };
  useEffect(() => {
    if (id && isAICourse) {
      getCoursesDetail(id);
    } else {
    }
  }, [id, isAICourse]);

  return (
    <Root>
      {loader ? (
        <Loader />
      ) : (
        <>
          <RatingPop
            isOpen={ratePop}
            handleClose={() => {
              setRatePop(false);
            }}
            name={data?.fullname}
          />
          <div className="course_title">
            <h2>{data.fullname}</h2>
            <span>
              <b>{Number(data.rating).toFixed(1)}</b>
              <IoIosStar
                size={12}
                style={{ color: "#FFD300", marginRight: "2px" }}
              />
              <button
                className="rate"
                onClick={() => {
                  setRatePop(true);
                }}
              >
                {string.rate}
              </button>
            </span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.summary }}></div>

          <hr />
          <div className="total_section flex">
            <h4>
              {string.totalSection}: <b>{data?.course_detail.length}</b>
            </h4>
            <h4>
              {string.duration}: <b>{data.duration}</b>
            </h4>
          </div>

          {/* Courses section ========== */}
          <div className="flex">
            <h3>{string.courseCurriculum}</h3>
            <h4>
              {string.learnBytes}: {data.view_activity}/{data.total_activity}
            </h4>
          </div>
          <div>
            {data?.course_detail?.map((item, ix) => (
              <CoursesAccordion
                data={item}
                count={ix + 1}
                isAICourse={isAICourse}
              />
            ))}
          </div>
        </>
      )}
    </Root>
  );
}
const Root = styled.section`
  hr {
    margin: 10px 0px;
  }
  .course_title {
    display: flex;
    justify-content: space-between;
    > span {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .total_section {
    margin: 10px 0px;
  }
  .rate {
    border-radius: 5px;
    padding: 4px 10px;
    background-color: #20b2aa;
    color: white;
    border: none;
    cursor: pointer;
  }
`;
