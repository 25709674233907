import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export default function NavBar() {
  const { string } = useSelector((state) => state.persistReducer);

  return (
    <Root>
      <ul className="nav_menu">
        <li>
          <StyledNavLink to="/">
            {({ isActive }) => (
              <LinkContent>
                <Icon
                  src={
                    isActive
                      ? "/assets/icons/home_active.svg"
                      : "/assets/icons/home.png"
                  }
                  alt="Home Icon"
                />
                <span>{string.home}</span>
              </LinkContent>
            )}
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/my-courses">
            {({ isActive }) => (
              <LinkContent>
                <Icon
                  src={
                    isActive
                      ? "/assets/icons/calender_active.png"
                      : "/assets/icons/calender.png"
                  }
                  alt="My Courses Icon"
                />
                <span>{string.myCourses}</span>
              </LinkContent>
            )}
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/certification">
            {({ isActive }) => (
              <LinkContent>
                <Icon
                  src={
                    isActive
                      ? "/assets/icons/certification_active.png"
                      : "/assets/icons/certification.png"
                  }
                  alt="My Courses Icon"
                />
                <span>{string.certification}</span>
              </LinkContent>
            )}
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/fav-video">
            {({ isActive }) => (
              <LinkContent>
                <Icon
                  style={{width:"14px"}}
                  src={
                    isActive
                      ? "/assets/icons/saved_active.png"
                      : "/assets/icons/saved.png"
                  }
                  alt="Fav Video Icon"
                />
                <span>{string.favVideo}</span>
              </LinkContent>
            )}
          </StyledNavLink>
        </li>
      </ul>
    </Root>
  );
}

const Root = styled.header`
  border-radius: 8px;
  .nav_menu {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
    justify-content: center;
    align-items:center;
    gap: 15px;
    @media (max-width: 950px) {
      flex-direction:column;
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #333;
  padding: 8px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  font-size: 14px;

  /* &:hover {
    border-bottom: 5px solid #cfecea;
    border-radius: 12px;
  }

  &.active {
    border-radius: 12px;
    width: 100%;
    border-bottom: 5px solid #cfecea;
  } */
`;

const LinkContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;
