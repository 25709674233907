import React from "react";
import styled from "styled-components";

export default function AiLoader() {
  return (
    <LoaderOverlay>
      <div className="gif_div"></div>{" "}
    </LoaderOverlay>
  );
}

const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .gif_div {
    background: url("/assets/images/aiLoader.gif") no-repeat center center;
    width: 200px;
    height:150px;
    background-size: contain;
  }
`;

const RotatingHalfCircle = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  border: 6px solid transparent;
  border-color: #3ca3a9;
  border-right-color: transparent;
  animation: rotate 1s linear infinite;
  transform-origin: center;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
