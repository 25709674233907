import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userApiAction } from "../redux/user/action";
import { ToastError } from "../components/Common/ToastMessage";
import Banner from "../components/Home/Banner";
import CoursesCard from "../components/Home/CoursesCard";
import styled from "styled-components";
import Loader from "../components/Common/Loader";

export default function Home() {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [courseData, setCourseData] = useState([]);
  const dispatch = useDispatch();
  const { user, string } = useSelector((state) => state.persistReducer);

  const getCourses = () => {
    const data = {
      token: user.token,
      userid: user.user_id,
      method: "mycourses",
    };
    setLoader(true);
    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError(string.msg.error);
        } else {
          setCourseData(e.data.my_courses);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <Root>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="achievement_section">
            <div
              onClick={() => {
                navigate("/ai-courses");
              }}
            >
              <img
                src={"/assets/icons/leaderboard_icon.svg"}
                alt="AI Courses"
              />
              <h3>{string.aiCourses}</h3>
            </div>
            <div>
              <img src={"/assets/icons/quiz.png"} alt="Quiz" />
              <h3>{string.quiz}</h3>
            </div>
          </div>

          {/*===========  My Courses ===========  */}
          <div className="courses">
            <h2>{string.myCourses}</h2>
            <NavLink className="navlink" to="/my-courses">
              {string.btn.viewAll}
            </NavLink>
          </div>
          <div className="courses_section">
            {courseData &&
              courseData?.map((item, ix) => (
                <CoursesCard data={item} key={ix} />
              ))}
          </div>
        </>
      )}
    </Root>
  );
}

const Root = styled.section`
  position: absolute;
  top: 330px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .achievement_section {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    gap: 25px;
    cursor: pointer;
    > div {
      border: 2px solid #86d0ca66;
      text-align: center;
      padding: 10px;
      border-radius: 10px;
      width: 100%;
      max-width: 200px;
      background-color: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
  .courses {
    display: flex;
    justify-content: space-between;
    > button {
      background: none;
      border: none;
      cursor: pointer;
    }
    > h2 {
      font-weight: 500;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      color: #fff;
    }
    .navlink {
      text-decoration: none;
      color: #000;
      background-color:#ffffff66;
      border-radius: 12px;
      padding: 10px;
    }
  }
  .courses_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
`;
