import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Profile from "../Profile";
import NavBar from "./NavBar";

export default function Topbar() {
  const [profileBox, setProfileBox] = useState(false);
  const { user, string } = useSelector((state) => state.persistReducer);
  const navigate = useNavigate();

  console.log("user?.first_name", user);

  return (
    <Root>
      <div className="logo_div">
        <img src="/assets/icons/learnbytes.png" alt="learnbytes" />
      </div>
      <div className="responsive_nav">
        <NavBar />
      </div>
      <div className="user_div">
        <h3>
          {string.hello}, {user.first_name + " " + user?.last_name}!
        </h3>
        <button
          className="user_img_div"
          onClick={() => {
            setProfileBox(true);
          }}
        >
          <p>{user?.first_name[0]}</p>
        </button>

        {/* Profile Box */}
        {profileBox && (
          <Profile isOpen={profileBox} handleClose={(e) => setProfileBox(e)} />
        )}
      </div>
    </Root>
  );
}
const Root = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 5px;
  border-bottom: 3px solid #3ca3a9;
  padding-right: 10px;
  .logo_div {
    width: 220px;
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .user_div {
    display: flex;
    align-items: center;
    gap: 10px;
    .user_img_div {
      cursor: pointer;
      border: 3px solid #43c5e4;
      display: flex;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      background-color: #80cec8;
      justify-content: center;
      align-items: center;
      p {
        font-size: 25px;
        color: #ffff;
      }
    }
  }
  .responsive_nav {
    @media (max-width: 950px) {
      display: none;
    }
  }
`;
