import {httpPost } from "../../utils/services/https";
import { URLS } from "../../utils/urls";

import { sagaTypes } from "./types";
import { all, takeLatest } from "redux-saga/effects";

function* postAPI({data, callback }) {
  try {
    const response = yield httpPost(`${URLS}`, data);
    console.log("response",response)
    if (response?.status === 200) {
      callback(response.data);
    } else {
      callback("error");
    }
  } catch (error) {
    callback("error");
  }
}


function* userApiRequest() {
  yield takeLatest(sagaTypes.USER_API, postAPI);
}

export default function* commonSaga() {
  yield all([userApiRequest()]);
}
