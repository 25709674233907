import React, { useState } from "react";
import styled from "styled-components";
import { FaStar } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { ToastError } from "./ToastMessage";
import { useSelector } from "react-redux";

export default function RatingPop({ isOpen, handleClose, name }) {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [comment, setComment] = useState("");
  const { user, string } = useSelector((state) => state.persistReducer);


  const handleRating = (rate) => {
    setRating(rate);
    ToastError("Work in progress");
  };

  const handleSubmit = () => {
    ToastError("Work in progress");
    // handleClose();
    // onSubmit({ rating, comment });
    setRating(0);
    setComment("");
  };

  return (
    <>
      {isOpen && (
        <Root>
          <div className="dialog-overlay">
            <div className="dialog-box">
              <button className="back_btn" onClick={handleClose}>
                <ImCross />
              </button>
              <h2>Rate {name}</h2>
              <img
                className="rate_img"
                src="/assets/images/rateAppTheme.svg"
                alt="Rating"
              />
              <StarContainer>
                {[...Array(5)].map((_, idx) => {
                  const rateValue = idx + 1;
                  return (
                    <FaStar
                      key={idx}
                      size={30}
                      onClick={() => handleRating(rateValue)}
                      onMouseEnter={() => setHover(rateValue)}
                      onMouseLeave={() => setHover(0)}
                      color={
                        rateValue <= (hover || rating) ? "#ffd029" : "#ccc"
                      }
                      style={{ cursor: "pointer", marginRight: "5px" }}
                    />
                  );
                })}
              </StarContainer>
              <h3>{string.shareFeedback}</h3>
              <textarea
                style={{ width: "100%", height: "100%" }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={string.typeHere}
              />
              <button
                className="primary_button"
                onClick={handleSubmit}
                style={{ width: "100%" }}
              >
                {string.btn.rate}
              </button>
            </div>
          </div>
        </Root>
      )}
    </>
  );
}
const Root = styled.div`
  height: 100%;
  width: 100%;
  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .back_btn {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 15px;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  textarea {
    font-size: 16px;
    border: 1px solid #319ea4;
    background-color: #f4fbfa;
    color: #7c889c;
    padding: 3px;
    resize: none;
    border-radius: 5px;
  }

  .dialog-box {
    background-color: white;
    position: relative;
    padding: 20px 20px 20px 20px;
    border-radius: 10px;
    width: 100%;
    gap: 10px;
    max-width: 350px;
    height: 100%;
    max-height: 700px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: none;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    h2 {
      text-align: center;
    }
    .rate_img {
      width: 200px;
      object-fit: contain;
    }
  }
`;

const StarContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;
