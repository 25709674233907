import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function ChatBot() {
  const navigate = useNavigate();
  return (
    <Root onClick={() => navigate("/chat")}>
      <img src="/assets/images/chat_bot.png" />
    </Root>
  );
}
const Root = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  > img {
    cursor: pointer;
  }
`;
