import { persistTypes, sagaTypes } from "./types";

// Persist Actions
export const stringAction = (data)=>({
    type: persistTypes.STRING,
    data,
})

export const userDataAction = (data)=>({
    type: persistTypes.USER_DATA,
    data,
})

//Saga Actions

//Auth=========>
export const userApiAction = (data, callback)=>({
    type: sagaTypes.USER_API,
    data,
    callback
})









