import React, { useState } from "react";
import Topbar from "./Topbar";
import styled from "styled-components";
import ChatBot from "../Chatbot";
import NavBar from "./NavBar";
import { RxCross2 } from "react-icons/rx";

export default function Layout({ children }) {
  const [showNav, setShowNav] = useState(false);

  return (
    <Root>
      <Topbar />
      <div className="mid_section">
        {children}
        <div
          className={`nav_container ${showNav ? "showNav" : "hideNav"}`}
          onClick={() => {
            setShowNav(false);
          }}
        >
          {/* {showNav && (
            <div
              className="dummyDiv"
              onClick={() => {
                setShowNav(false);
              }}
            ></div>
          )} */}
          <div style={{ position: "relative", zIndex: "99" }}>
            <NavBar />
            <button
              className="cls_btn"
              onClick={() => {
                setShowNav(false);
              }}
            >
              <RxCross2 />
            </button>
          </div>
        </div>

        {/* Button to show NavBar */}
        {!showNav && (
          <ToggleButton onClick={() => setShowNav(true)}>{">"}</ToggleButton>
        )}
      </div>
      <ChatBot />
    </Root>
  );
}

// Styled Components
const Root = styled.section`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  .mid_section {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: scroll;
    padding: 15px 15px 0 15px;

    .nav_container {
      position: fixed;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 50vh;
      left: 0px;
      width: 100px;
      background-color: white;
      z-index: 5;
      border-radius: 15px;
      padding: 15px 0;
      transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

      .dummyDiv {
        position: fixed;
        z-index: 4;
        top: -100vh;
        border: 0;
        left: 0;
        right: 0;
        height: 200vh;
        width: 100vw;
      }
    }

    .showNav {
      transform: translateX(0);
      opacity: 1;

      .cls_btn {
        position: absolute;
        top: -16px;
        right: 3px;
        background-color: transparent;
        border: none;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .hideNav {
      transform: translateX(-120%);
      opacity: 0;
    }
  }
`;

const ToggleButton = styled.button`
  display: none;
  @media (max-width: 950px) {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -5px;
    margin: auto;
    height: 40px;
    width: 10px;
    background-color: #3ca3a9;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 999999;
    transition: background-color 0.3s;

    &:hover {
      background-color: #359899;
    }
  }
`;
