import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MdLanguage, MdOutlinePrivacyTip } from "react-icons/md";
import { LiaThumbsUpSolid } from "react-icons/lia";
import { IoShareOutline, IoInformationCircleOutline } from "react-icons/io5";
import { GrDocumentText } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { stringAction, userDataAction } from "../../redux/user/action";
import { ToastSuccess } from "../Common/ToastMessage";
import {
  stringDutch,
  stringEng,
  stringSpan,
} from "../../utils/services/string";

export default function Profile({ isOpen, handleClose }) {
  const [view, setView] = useState("main");
  const { user, string } = useSelector((state) => state.persistReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleDialog = () => {
    handleClose(!isOpen);
    setView("main");
  };

  const openLanguageSelection = () => {
    setView("language");
  };

  const goBackToMainView = () => {
    if (view !== "main") {
      setView("main");
    } else {
      toggleDialog();
    }
  };

  const changeLanguage = (type) => {
    if (type == "eng") {
      dispatch(stringAction(stringEng));
    }
    if (type == "spn") {
      dispatch(stringAction(stringSpan));
    }
    if (type == "dutch") {
      dispatch(stringAction(stringDutch));
    }
  };

  const handleLogout = () => {
    dispatch(userDataAction({}));
    localStorage.setItem("token", "");
    navigate("/login");
    ToastSuccess(string.msg.logout);
  };

  return (
    <Root>
      {isOpen && (
        <div className="dialog-overlay" onClick={toggleDialog}>
          <div className="dialog-box" onClick={(e) => e.stopPropagation()}>
            <button
              className="back_btn"
              onClick={() => {
                goBackToMainView();
              }}
            >
              <IoMdArrowRoundBack />
            </button>
            <div
              className="profile-image"
              src="/path/to/profile-image.jpg"
              alt="Profile"
            >
              {user?.first_name[0]}
            </div>
            {view === "main" ? (
              <>
                <h3>{string.profile}</h3>
                <ul>
                  <li onClick={openLanguageSelection}>
                    <MdLanguage /> {string.changeLanguage}
                  </li>
                  <hr />
                  {/* <li>
                    <LiaThumbsUpSolid /> {string.rateApp}
                  </li> */}
                  <hr />
                  <li>
                    <IoShareOutline /> {string.shareApp}
                  </li>
                  <hr />
                  <li>
                    <IoInformationCircleOutline /> {string.aboutUs}
                  </li>
                  <hr />
                  <li>
                    <MdOutlinePrivacyTip /> {string.privacyPolicy}
                  </li>
                  <hr />
                  <li>
                    <GrDocumentText /> {string.tAndC}
                  </li>
                </ul>
                <button className="logout-button" onClick={handleLogout}>
                  {string.btn.logout}
                </button>
                {/* <button className="close-button" onClick={toggleDialog}>
                  Close
                </button> */}
              </>
            ) : (
              <>
                <h3>{string.btn.selectLang}</h3>
                <ul>
                  <li
                    onClick={() => {
                      changeLanguage("eng");
                    }}
                  >
                    {string.btn.eng}
                  </li>
                  <hr />
                  <li
                    onClick={() => {
                      changeLanguage("spn");
                    }}
                  >
                    {string.btn.spn}
                  </li>
                  <hr />
                  <li
                    onClick={() => {
                      changeLanguage("dutch");
                    }}
                  >
                    {string.btn.dutch}
                  </li>
                  <hr />
                </ul>
                <button className="save-button" onClick={goBackToMainView}>
                  {string.btn.save}
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </Root>
  );
}

const Root = styled.div`
  text-align: center;
  margin-top: 50px;
  h3 {
    text-align: center;
  }

  .dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: flex-end;
    z-index: 10;
  }

  .dialog-box {
    background-color: white;
    position: relative;
    padding: 20px 20px 5px 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 350px;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow-y: scroll;
    .back_btn {
      position: absolute;
      font-size: 25px;
      display: flex;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
    .profile-image {
      width: 100px;
      height: 100px;
      border-radius: 20px;
      object-fit: cover;
      display: block;
      margin: 0 auto 15px;
      border: 3px solid #319ea4;
      display: flex;
      font-size: 50px;
      color: #fff;
      background-color: #80cec8;
      justify-content: center;
      align-items: center;
    }

    h2 {
      margin-bottom: 15px;
      font-size: 20px;
      text-align: center;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 15px;
      flex-grow: 1;

      li {
        display: flex;
        align-items: center;
        margin: 8px 0;
        cursor: pointer;
        font-size: 16px;
        padding: 10px 0;

        svg {
          margin-right: 8px;
          font-size: 18px;
        }
      }

      hr {
        border: none;
        border-top: 1px solid #e0e0e0;
        margin: 0;
      }
    }
  }

  .logout-button,
  .close-button,
  .save-button {
    display: block;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }

  .logout-button {
    background-color: #3ca3a9;
    color: white;
  }

  .close-button {
    background-color: #ccc;
  }

  .save-button {
    background-color: #319ea4;
    color: white;
  }
`;
