import React from "react";
import Slider from "react-slick";
import styled from "styled-components";

// Import slick-carousel CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from "./Home";

export default function Test() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3500,
    fade: false,
  };

  // Data array containing slide information
  const slidesData = [
    {
      image: "/assets/images/banner_online.jpg",
      title: "Discover the Future of Learning",
      description: "Join our courses and explore new possibilities with AI-powered learning.",
      buttonText: "Explore Now",
    },
    {
      image: "/assets/images/banner_ai.jpg",
      title: "Innovate and Grow",
      description: "Stay ahead in your career with our expertly designed curriculum.",
      buttonText: "Start Today",
    },
    {
      image: "/assets/images/banner_edu.jpg",
      title: "Learn Anytime, Anywhere",
      description: "Access quality education at your convenience, wherever you are.",
      buttonText: "Get Started",
    },
  ];

  return (
    <Root>
      <div className="slider_container">
        <Slider {...settings}>
          {slidesData.map((slide, index) => (
            <div className="slide" key={index}>
              <img src={slide.image} alt={`Slide ${index + 1}`} />
              <Overlay />
              <BannerContent>
                <h1>{slide.title}</h1>
                <p>{slide.description}</p>
                <button>{slide.buttonText}</button>
              </BannerContent>
            </div>
          ))}
        </Slider>
        <Home/>
      </div>
    </Root>
  );
}

const Root = styled.div`
  margin-bottom: 10px;
  margin-top: -20px;

  .slider_container {
    position: relative;
    width: 100%;
    /* max-width: 1200px; */
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    > div {
      width: 100%;
      height: 100%;
      /* position: relative; */
    }
    /* img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    } */
  }

  .slide {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
  }

  .slide img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .slick-slide {
    opacity: 1 !important;
    visibility: visible !important;
  }

  .slick-list {
    overflow: visible; /* Ensure the content is not clipped */
  }

  .slick-dots {
    /* bottom: 15px; */
    top:250px;

  }

  .slick-dots li button:before {
    font-size: 12px;
    color: #fff;
  }

  .slick-dots li.slick-active button:before {
    color: #ffd029;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  z-index: 1;
`;

const BannerContent = styled.div`
  position: absolute;
  top: 150px;
  left: 10%;
  transform: translateY(-50%);
  color: #fff;
  z-index: 3;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  p {
    font-size: 1.25rem;
    margin-bottom: 30px;
    max-width: 500px;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  }

  button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #ffd029;
    color: #000;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e0b125;
    }
  }
`;
