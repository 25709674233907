export const stringEng = {
  // Top Bar and Side Menue
  hello: "Hello",
  home: "Home",
  myCourses: "My Courses",
  certification: "Certification",
  favVideo: "Fav Video",
  // Top Bar and Side Menue
  login: "Login",
  forgetPass: "Forgot Password",
  aiCourses: "AI Courses",
  quiz: "Quiz",
  favouriteVideo: "Favourite Video",
  rate: "Rate",
  totalSection: "Total Section",
  duration: "Duration",
  courseCurriculum: "Course Curriculum",
  learnBytes: "Learn Bytes",
  section: "Section",
  questions: "Questions",
  quizAttemptDetail: "Quiz Attempt Details",
  attempts: "Attempts",
  totalQuestion: "Total Questions",
  correct: "Correct",
  wrong: "Wrong",
  aiAnalysisHead: "AI Analysis of Course Knowledge Area",
  aiAssistant: "AI Assistant",
  saladNotRunning: "Salad is not running",
  welcomeMsg: "How can I assist you today",
  changeLanguage: "Change Languages",
  profile: "Profile",
  rateApp: "Rate App",
  shareApp: "Share App",
  aboutUs: "About Us",
  privacyPolicy: "Privacy Policy",
  tAndC: "Terms and Conditions",
  aiCourseCreation:
    "Let AI assist you in creating your personalized course path",
  changeLang: "Change Language",
  shareFeedback: "Please Share Your Feedback",
  typeHere: "Type Here...",
  btn: {
    login: "Login",
    viewAll: "View All >>",
    logout: "Logout",
    selectLang: "Select Language",
    eng: "English",
    spn: "Español",
    dutch: "Nederlands",
    save: "Save",
    clickHere: "Click Here",
    continue: "Continue",
    rate: "Rate",
    backToLog:"Back to login",
    sendLink:"Send Link"
  },
  msg: {
    error: "Something went wrong",
    invalidUser:"Invalid username",
    verificationLink:"Verification link sent",
    searchIssue:
      "Apologies, but there seems to be an issue with the search. Please try again.",
    msgCutOff:
      "It seems like your message might have been cut off. Could you please provide more details or clarify what you need assistance with? I'm here to help!",
    enterYourMessage: "Enter your message",
    password: "Password",
    username: "Username",
    logout: "Logout Successfully",
    failedQuiz: "You have failed the quiz. Please try again.",
    congrat: "Congratulations!",
    passQuiz1: "You have successfully passed the AI Course.",
    passQuiz2: "Now you can take the final quiz.",
  },
};

export const stringSpan = {
  // Barra superior y menú lateral
  hello: "Hola",
  home: "Inicio",
  myCourses: "Mis Cursos",
  certification: "Certificación",
  favVideo: "Video Favorito",
  // Barra superior y menú lateral
  login: "Iniciar Sesión",
  forgetPass: "Olvidé Mi Contraseña",
  aiCourses: "Cursos de IA",
  quiz: "Cuestionario",
  favouriteVideo: "Video Favorito",
  rate: "Calificar",
  totalSection: "Sección Total",
  duration: "Duración",
  courseCurriculum: "Plan de Estudios del Curso",
  learnBytes: "Bytes de Aprendizaje",
  section: "Sección",
  questions: "Preguntas",
  quizAttemptDetail: "Detalles del Intento de Cuestionario",
  attempts: "Intentos",
  totalQuestion: "Preguntas Totales",
  correct: "Correcto",
  wrong: "Incorrecto",
  aiAnalysisHead: "Análisis de IA del Área de Conocimiento del Curso",
  aiAssistant: "Asistente de IA",
  saladNotRunning: "Salad no está funcionando",
  welcomeMsg: "¿Cómo puedo asistirte hoy?",
  changeLanguage: "Cambiar Idiomas",
  profile: "Perfil",
  rateApp: "Calificar App",
  shareApp: "Compartir App",
  aboutUs: "Sobre Nosotros",
  privacyPolicy: "Política de Privacidad",
  tAndC: "Términos y Condiciones",
  aiCourseCreation: "Deja que la IA te asista en crear tu camino de curso personalizado",
  changeLang: "Cambiar Idioma",
  shareFeedback: "Por Favor Comparta Su Opinión",
  typeHere: "Escribe Aquí...",
  btn: {
    login: "Iniciar Sesión",
    viewAll: "Ver Todo >>",
    logout: "Cerrar Sesión",
    selectLang: "Seleccionar Idioma",
    eng: "English",
    spn: "Español",
    dutch: "Nederlands",
    save: "Guardar",
    clickHere: "Haz Clic Aquí",
    continue: "Continuar",
    rate: "Calificar",
  },
  msg: {
    error: "Algo salió mal",
    searchIssue: "Lo siento, parece que hay un problema con la búsqueda. Por favor, inténtelo de nuevo.",
    msgCutOff: "Parece que tu mensaje se ha cortado. ¿Podrías proporcionar más detalles o aclarar en qué necesitas ayuda? ¡Estoy aquí para ayudar!",
    enterYourMessage: "Ingresa tu mensaje",
    password: "Contraseña",
    username: "Nombre de Usuario",
    logout: "Cierre de Sesión Exitoso",
    failedQuiz: "Has fallado el cuestionario. Por favor, inténtalo de nuevo.",
    congrat: "¡Felicidades!",
    passQuiz1: "Has aprobado con éxito el Curso de IA.",
    passQuiz2: "Ahora puedes realizar el cuestionario final.",
  },
}

export const stringDutch = {
  // Bovenbalk en zijmenu
  hello: "Hallo",
  home: "Startpagina",
  myCourses: "Mijn Cursussen",
  certification: "Certificering",
  favVideo: "Favoriete Video",
  // Bovenbalk en zijmenu
  login: "Inloggen",
  forgetPass: "Wachtwoord Vergeten",
  aiCourses: "AI Cursussen",
  quiz: "Quiz",
  favouriteVideo: "Favoriete Video",
  rate: "Beoordelen",
  totalSection: "Totaal Sectie",
  duration: "Duur",
  courseCurriculum: "Cursus Curriculum",
  learnBytes: "Leerbytes",
  section: "Sectie",
  questions: "Vragen",
  quizAttemptDetail: "Details van Quizpoging",
  attempts: "Pogingen",
  totalQuestion: "Totale Vragen",
  correct: "Correct",
  wrong: "Onjuist",
  aiAnalysisHead: "AI Analyse van Kennisgebied van de Cursus",
  aiAssistant: "AI Assistent",
  saladNotRunning: "Salad werkt niet",
  welcomeMsg: "Hoe kan ik je vandaag helpen?",
  changeLanguage: "Talen Wijzigen",
  profile: "Profiel",
  rateApp: "App Beoordelen",
  shareApp: "App Delen",
  aboutUs: "Over Ons",
  privacyPolicy: "Privacybeleid",
  tAndC: "Algemene Voorwaarden",
  aiCourseCreation:
    "Laat AI je helpen bij het creëren van je persoonlijke cursuspad",
  changeLang: "Taal Wijzigen",
  shareFeedback: "Deel Alstublieft Uw Feedback",
  typeHere: "Typ Hier...",
  btn: {
    login: "Inloggen",
    viewAll: "Bekijk Alles >>",
    logout: "Uitloggen",
    selectLang: "Selecteer Taal",
    eng: "English",
    spn: "Español",
    dutch: "Nederlands",
    save: "Opslaan",
    clickHere: "Klik Hier",
    continue: "Doorgaan",
    rate: "Beoordelen",
  },
  msg: {
    error: "Er is iets misgegaan",
    searchIssue: "Sorry, er lijkt een probleem te zijn met de zoekopdracht. Probeer het opnieuw.",
    msgCutOff: "Het lijkt erop dat je bericht is afgekapt. Kunt u meer details geven of verduidelijken waar u hulp bij nodig hebt? Ik ben hier om te helpen!",
    enterYourMessage: "Voer uw bericht in",
    password: "Wachtwoord",
    username: "Gebruikersnaam",
    logout: "Succesvol Uitgelogd",
    failedQuiz: "Je bent gezakt voor de quiz. Probeer het opnieuw.",
    congrat: "Gefeliciteerd!",
    passQuiz1: "Je hebt de AI-cursus succesvol afgerond.",
    passQuiz2: "Nu kun je de eindquiz maken.",
  },
}


