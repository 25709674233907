import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastError } from "../components/Common/ToastMessage";
import { userApiAction } from "../redux/user/action";
import styled from "styled-components";
import Loader from "../components/Common/Loader";

export default function Quiz() {
  const { id, practice } = useParams();
  const [loader, setLoader] = useState(false);
  const [quizData, setQuizData] = useState({});
  const { user, string } = useSelector((state) => state.persistReducer);
  const [handleIndex, setHandleIndex] = useState(0);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getQuiz = () => {
    const data = {
      token: user.token,
      userid: user.user_id,
      method: "get_course_quiz",
      courseid: id,
      is_practice: practice,
    };
    setLoader(true);
    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError("Something Went Wrong");
        } else {
          setQuizData(e.data.quiz);
          setTotalQuestion(e?.data?.quiz?.question_data?.length);
        }
        setLoader(false);
      })
    );
  };

  const nextQuestion = (questionId, optionId, quizId) => {
    setLoader(true);
    const data = {
      token: user.token,
      userid: user.user_id,
      method: "save_quiz_progress",
      courseid: id,
      quizid: quizId,
      questionid: questionId,
      optionid: optionId,
      is_quiz_finish: handleIndex + 1 == totalQuestion ? "1" : "0",
      is_practice: practice,
    };

    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError("Something Went Wrong");
        }
        setLoader(false);
      })
    );

    if (handleIndex + 1 != totalQuestion) {
      setHandleIndex(handleIndex + 1);
    } else {
      navigate(`/quiz-result/${id}/${quizId}/${practice}`);
    }

    console.log("finalQuestion", data);
  };

  useEffect(() => {
    if (id && practice) {
      getQuiz();
    }
  }, [id, practice]);

  console.log("check343535", practice)

  return (
    <Root>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="question_box">
            <h3 style={{ textAlign: "center", color: "#008890" }}>
              {string.questions} {handleIndex + 1}/{quizData?.question_data?.length}
            </h3>
            <h3>
              {quizData.question_data &&
                quizData?.question_data[handleIndex].name}
              ?
            </h3>
          </div>
          <div className="option_box">
            {quizData.question_data &&
              quizData?.question_data[handleIndex]?.question_options?.map(
                (items, ix) => {
                  return (
                    <div
                      key={ix}
                      onClick={() => {
                        nextQuestion(
                          items.questionid,
                          items.id,
                          quizData?.question_data[handleIndex].quizid
                        );
                      }}
                    >
                      {items.name}{" "}
                    </div>
                  );
                }
              )}
          </div>
        </>
      )}
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 1024px;
  .question_box {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px 10px 60px 10px;
    border-left: 1px solid lightgray;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    h3 {
      width: 100%;
    }
  }
  .option_box {
    display: flex;
    flex-direction: column;
    gap: 20px;
    > div {
      cursor: pointer;
      padding: 10px;
      border-radius: 12px;
      background-color: #fff;
      border-left: 1px solid lightgray;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
        rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
      &:hover {
        background-color: #80cec8;
        color: #fff;
        transition: 0.4s;
      }
    }
  }
`;
