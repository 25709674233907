import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../components/Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ToastError } from "../components/Common/ToastMessage";
import { userApiAction } from "../redux/user/action";
import CertificationCard from "../components/Home/CertificationCard";

export default function Certification() {
  const [loader, setLoader] = useState(true);
  const { user, string } = useSelector((state) => state.persistReducer);
  const dispatch = useDispatch();
  const [certificateData, setCertificateData] = useState([]);

  const getCertification = () => {
    const data = {
      token: user.token,
      userid: user.user_id,
      method: "get_certificates",
    };
    setLoader(true);
    dispatch(
      userApiAction(data, (e) => {
        if (e == "error") {
          ToastError(string.msg.error);
        } else {
          setCertificateData(e.data.certificates);
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    getCertification();
  }, []);

  return (
    <Root>
      {loader && <Loader />}
      {certificateData &&
        certificateData?.map((items,ix) => {
          return <CertificationCard data={items} key={ix} />;
        })}
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
`;
