import React, { useEffect, useState } from "react";
import CoursesCard from "../components/Home/CoursesCard";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { userApiAction } from "../redux/user/action";
import { ToastError } from "../components/Common/ToastMessage";
import Loader from "../components/Common/Loader";


export default function Courses() {

  const [loader, setLoader] = useState(true);
  const [courseData, setCourseData] = useState([])
  const dispatch = useDispatch();
  const { user, string } = useSelector((state) => state.persistReducer);

  const getCourses = () => {
    const data = {
      token: user.token,
      userid: user.user_id,
      method: "mycourses",
    };
    dispatch(
      userApiAction(data,(e) => {
        if (e == "error") {
          ToastError(string.msg.error);
        }else{
          setCourseData(e.data.my_courses)
        }
        setLoader(false);
      })
    );
  };

  useEffect(() => {
    setLoader(true);
    getCourses()
  }, []);

  console.log("test4545454",courseData)

  return (
    <Root>
      {loader && <Loader/>}
      <div className="courses_section">
        {courseData && courseData?.map((item) => (
          <CoursesCard data={item}/>
        ))}
      </div>
    </Root>
  );
}

const Root = styled.section`
  .courses_section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
`;
